import { message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getByIdmanagement, updatemanagement } from "../store/managementSlice";
export const ChargeManagement = () => {


  const dispatch = useDispatch();

  const { data: management, status } = useSelector((state) => state?.management);
  console.log('ddddddddddddd', management);
  useEffect(() => {
    dispatch(getByIdmanagement(1));
  }, [])

  const HandleSubmit = (values) => {
    console.log(values);
    if (values.card.value.length === 0) {
      message.error('Please enter card price')
    } else if (values.appointment.value.length === 0) {
      message.error('Please enter appointment price')
    }
    else {
      const params = {
        'card': values.card.value,
        'appointment': values.appointment.value
      }
      dispatch(updatemanagement(1,params))
    }
  }




  return (
    <>
      <div ClassName="m-3">

        <form className="" onSubmit={(e) => { e.preventDefault(); HandleSubmit(e.target) }} >
         
          <div className="bg-white p-3 rounded-3 shadow m-4">
          <h3 className="text-start p-2">Charge Management</h3>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Card Price</label>
                <input name="card" placeholder="Card Price" type="number" className="form-control" defaultValue={management?.card} />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Appointment Price</label>
                <input name="appointment" placeholder="Appointment Price" type="number" className="form-control" defaultValue={management?.appointment} />
              </div>

            </div>


            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
