import React from 'react'


function LabReportHistory() {
  return (
    <>

<div className=' m-3'>
<h3 className='text-start p-2'>Lab Report History</h3>
    </div>

    <div className=' m-4 rounded shadow bg-white'>
    

    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Name </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Number </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Add Button </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Name & Customer </label>
<input type="text" className='form-control ' />
</div>
</div>
 <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Date of Added </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Customer Report Detail </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Attachment & Report  </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Summit Options </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Same as Lab Reports  </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Eddit Options </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3 d-flex justify-content-between'>

<div className='col-md-12'>
<button type="Register" className='btn btn-primary' >Register</button>
</div>


</div>
        </div>






    </>
  )
}

export default LabReportHistory