import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { deletewebsites, fetchwebsites } from "../../store/websitesSlice";
function WebsiteSetting() {
  $(document).ready(function () {
    setTimeout(function () {
      $("#AllData").DataTable({
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        bDestroy: true,
        // dom: 'Bfrtip',
        // buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        dispatch(deletewebsites(id));
      },
    });
  };
  const dispatch = useDispatch();

  const { data: websites, status } = useSelector((state) => state?.websites);
  console.log("ddddddddddddd", websites);
  useEffect(() => {
    dispatch(fetchwebsites());
  }, []);
  return (
    <>
      <section className="m-4 " >
        <div className="container-fluid border-0 rounded-4 bg-white p-4 shadow">
          <h3 className="text-start p-2">Website Setting</h3>
          <div className=" row  p-3">
            <div className="col-md-12 text-end">
              <Link to="/add_websiteSetting" className="btn mb-3 btn-danger">
                Add
              </Link>

              <div className="table-responsive" style={{overflowX:'hidden'}} >
         <table  class="table table-striped" id="AllData">
            <thead>
              <tr>
                <th scope="col">SL</th>
                <th scope="col">Logo</th>
                <th scope="col">Video Link</th>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                {/* <th scope="col">Permission for Staff</th> */}
                <th scope="col">Image</th>
          
                {/* <th scope="col">List & Staff</th> */}
                <th scope="col">Edit Option</th>
              </tr>
            </thead>
            <tbody>
              { websites?.length>0?websites?.map((item, index) => {
                return (
                  <>
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.username}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.email}</td>
                      <td>{item?.password}</td>
                      <td>{item?.password}</td>
                      
                      {/* <td>Otto</td> */}
                      {/* <td>@mdo</td> */}
                      <td>
                        <div className=" d-flex justify-content-center">
                          <Link to={`/edit_staff/${item?.id}`}>
                            <i
                              style={{ color: "blue", fontSize: "20px" }}
                              class="bi bi-pencil-square"
                            ></i>
                          </Link>
                          <i
                            onClick={() => {
                              onDeleteStudent(item?.id);
                            }}
                            class="bi bi-trash3 ms-3"
                            style={{ color: "red", fontSize: "20px" }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              }):''}
            </tbody>
          </table>
         </div>
            </div>
          </div>
        
        </div>
      </section>
    </>
  );
}

export default WebsiteSetting;
