import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const appointmentSlice=createSlice({
    name: "appointment",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setappointment(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setappointment,setStatus}=appointmentSlice.actions
export default appointmentSlice.reducer




// thunk

export function fetchappointment(){
    return async function fetchappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}appointment/todayAppointment`).then(
                (resp) => {
                    dispatch(setappointment(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createappointment(param){
    return async function createappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}appointment/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("appointment Created Successfully!")
                }
                else if (res.data?.message === "No user found!!!"){
                    message.success("Please Register Your Account!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/login`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);

            if (err.data?.response?.data?.error === "ok") {
                message.success("Please Register Your Account");
            }
          
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateappointment(id,params){
    return async function updateappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}appointment/update/${id}`,params).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("appointment Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Appointment%20History`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteappointment(id){
    return async function deleteappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}appointment/delete/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdappointment(id){
    return async function getByIdappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/appointment/${id}`).then(
                (resp) => {
                    dispatch(setappointment(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function AppointmentToggle(id,params){
    console.log(id,params,'params');
    return async function getByIdappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}appointment/update/${id}`,params).then(
                (resp) => {
                    dispatch(setappointment(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function fetchappointmentHistory(id){
    return async function getByIdappointmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}appointment/get`).then(
                (resp) => {
                    dispatch(setappointment(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
