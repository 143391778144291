import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import LineCharts from '../graphchart/LineCharts'
import { fetchusers } from '../store/usersSlice';
function VisitorsTable() {



  const dispatch = useDispatch();

  const { data: users, status } = useSelector((state) => state?.users);
  console.log('ddddddddddddd', users);
  useEffect(() => {
    dispatch(fetchusers());
  }, [])
  return (
    <>
<div className='p-4'>

<div className=' card container-fluid border-0 bg-white p-4 shadow'>
<h3 className='text-start p-2'>Visitors Total</h3>
  <LineCharts/>







</div>








</div>





    </>
  )
}

export default VisitorsTable