import { message, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createstaffs } from "../store/staffsSlice";
export const Staffs = () => {
  const dispatch = useDispatch();

  const [permssion, setPermissions] = useState([]);
  console.log(permssion);
  const perdata = (e) => {
    let temp = [...permssion, e.target.value];

    console.log(temp.includes(permssion));
    console.log(permssion);

    setPermissions(temp);
  };

  const HandleSubmit = (values) => {



    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      const checkboxValues = [];

      checkboxes.forEach(checkbox => {
        if (checkbox.checked) {
          checkboxValues.push(checkbox.value);
        }
      });

      console.log('The checked checkbox values are:', checkboxValues);




    if (values.username.value.length === 0) {
      message.error("Please enter your username");
    } else if (values.phone.value.length === 0) {
      message.error("Please enter your phone");
    } else if (values.email.value.length === 0) {
      message.error("Please enter your email");
    } else if (values.password.value.length === 0) {
      message.error("Please enter your password");
    } else {

      dispatch(
        createstaffs({
          username: values.username.value,
          phone: values.phone.value,
          email: values.email.value,
          permission: checkboxValues,
          password: values.password.value,
        })
      );
    }
  };

  return (
    <>
      <div ClassName="m-3">
        <form
          className=""
          onSubmit={(e) => {
            e.preventDefault();
            HandleSubmit(e.target);
          }}
        >
          <h3 className="text-start p-2">Add Staffs</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label> Name </label>
                <input
                  name="username"
                  placeholder="Name"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Number</label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Number"
                  className="form-control"
                />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label> Email </label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="form-control"
                />
              </div>

              <div className="col-md-6">
                <label> Password </label>
                <input
                  type="text"
                  name="password"
                  placeholder="Password"
                  className="form-control"
                />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Permissions:</label>
                  <div className="d-flex flex-wrap">
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Dashboard"
                        value="Dashboard"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" htmlFor="Dashboard">
                        Dashboard
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Doctor"
                        value="Doctor"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Doctor">
                        Doctor
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Department"
                        value="Department"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Department">
                        Department
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Lab Reports Add"
                        value="Lab Reports Add"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Lab Reports Add">
                        Lab Reports Add
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Lab Reports History"
                        value="Lab Reports History"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Lab Reports History">
                        Lab Reports History
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Charge Management"
                        value="Charge Management"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Charge Management">
                        Charge Management
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Users"
                        value="Users"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Users">
                        Users
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Staffs"
                        value="Staffs"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Staffs">
                        Staffs
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Registration Card Pending"
                        value="Registration Card Pending"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label
                        class="form-check-label"
                        for="Registration Card Pending"
                      >
                        Registration Card Pending
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Registration Card List"
                        value="Registration Card List"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label
                        class="form-check-label"
                        for="Registration Card List"
                      >
                        Registration Card List
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Donation History"
                        value="Donation History"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Donation History">
                        Donation History
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Appointments Today"
                        value="Appointments Today"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Appointments Today">
                        Appointments Today
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Appointment History"
                        value="Appointment History"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Appointment History">
                        Appointment History
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Visitors Total"
                        value="Visitors Total"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Visitors Total">
                        Visitors Total
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Monthly Revenue Report"
                        value="Monthly Revenue Report"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label
                        class="form-check-label"
                        for="Monthly Revenue Report"
                      >
                        Monthly Revenue Report
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Payment Bank Alfala"
                        value="Payment Bank Alfala"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Payment Bank Alfala">
                        Payment Bank Alfala
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Latest News"
                        value="Latest News"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Latest News">
                        Latest News
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Services"
                        value="Services"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Services">
                        Services
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="About Settings"
                        value="About Settings"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="About Settings">
                        About Settings
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Website Settings"
                        value="Website Settings"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Website Settings">
                        Website Settings
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Slider Settings"
                        value="Slider Settings"
                        name="permiss"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Slider Settings">
                        Slider Settings
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary">
                  Register
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
