import React, { useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchusers } from "../store/usersSlice";
import $ from "jquery";
import { Modal } from "antd";
import { CardToggle, deletecard, fetchcard } from "../store/cardSlice";
import { Link } from "react-router-dom";
import { GrView } from "react-icons/gr";
function RegisterCardlistTable() {
  const dispatch = useDispatch();

  const { data: card, status } = useSelector((state) => state?.card);
  console.log("ddddddddddddd", card);
  useEffect(() => {
    dispatch(fetchcard());

    $(document).ready(function () {
      setTimeout(function () {
        $("#AllData").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          bDestroy: true,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
      }, 1000);
    });
  }, []);

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        dispatch(deletecard(id));
      },
    });
  };

  return (
    <>
      <div className="m-3">
        <div className=" card border-0 container bg-white p-4 shadow">
          <h3 className="text-start p-2">Registration Card List</h3>
          <div className=" row  text-end p-3  d-flex justify-content-between">
            {/* <div className='col-md-12'>
  <Link to="/users" className="btn btn-danger">Add</Link>
</div> */}
          </div>
          <table class="table" id="AllData">
            <thead>
              <tr>
                <th scope="col">#SL</th>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                {/* <th scope="col">Actve Customer</th> */}

                <th scope="col">Relative</th>
                <th scope="col">Age</th>
                <th scope="col">Date</th>
                <th scope="col">Address</th>
                <th scope="col">status</th>
                <th scope="col">Block</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {card.length > 0
                ? card.map((item, index) => {
                    if (item?.status === "approved") {
                      return (
                        <>
                          <tr>
                            <td scope="row">{index + 1}</td>
                            <td>{item?.name}</td>
                            {/* <td>@mdo</td> */}
                            <td>{item?.phone}</td>
                            <td>{item?.relative}</td>
                            <td>{item?.age}</td>
                            <td>{item?.date}</td>
                            <td>{item?.address}</td>
                            <td>
                              {item?.status === "pending" ? (
                                <span className="badge text-bg-warning">
                                  {item?.status}
                                </span>
                              ) : (
                                <span className="badge text-bg-success">
                                  {item?.status}
                                </span>
                              )}
                            </td>
                            <td>
                              <label class="switch">
                                {item?.block == true ? (
                                  <input
                                    type="checkbox"
                                    onClick={() => {
                                      dispatch(fetchcard());

                                      const parms = {
                                        'block':false,
                                        'status':'pending'
                                      }
                                      dispatch(CardToggle(item?.id,parms));
                                      dispatch(fetchcard());
                                    }}
                                    defaultChecked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    onClick={() => {
                                      dispatch(fetchcard());
                                      const parms= {
                                        'block': true,
                                        'status':'approved'
                                      }
                                      dispatch(CardToggle(item?.id,parms));
                                      dispatch(fetchcard());
                                    }}
                                  />
                                )}
                                <span class="slider round"></span>
                              </label>
                            </td>

                            <td>
                              <div className=" d-flex justify-content-center align-items-center">
                                <Link to={`/edit_card_registraion/${item?.id}`}>
                                  <i
                                    style={{ color: "blue", fontSize: "20px" }}
                                    class="bi bi-pencil-square"
                                  ></i>
                                </Link>
                                <i
                                  onClick={() => {
                                    onDeleteStudent(item?.id);
                                  }}
                                  class="bi bi-trash3 px-2"
                                  style={{ color: "red", fontSize: "20px" }}
                                ></i>

                                <Link to={`/card/${item?.id}`}>
                                  <GrView color="blue" size={20} />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    }
                  })
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default RegisterCardlistTable;
