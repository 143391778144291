import { message, Modal} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createservices } from "../../store/servicesSlice";
export const AddServices = () => {
 

  const dispatch = useDispatch();

  const [permssion, setPermissions] = useState([]);
  console.log(permssion);
  const perdata = (e) => {
    let temp = [...permssion, e.target.value];
    setPermissions(temp);
  };


  const HandleSubmit = (values)=>{
       console.log(values);
    if(values.title.value.length===0){

      message.error('Please enter your title')
    }
    else if (values.icon.files.length===0){
      message.error('Please enter your icon')
    }else if(values.image.files.length===0){
      message.error('Please enter your image')

    }else if (values.shortDescription.value.length===0){

      message.error('Please enter your description')

    }
    else if (values.longDescription.value.length===0){

        message.error('Please enter your description')
  
      }

    else {
        console.log(values.icon.files[0])
        console.log(values.image.files[0])

        const params = new FormData()

        params.append('title',values.title.value)
        params.append('icon',values.icon.files[0])
        params.append('image',values.image.files[0])
        params.append('shortDescription',values.shortDescription.value)
        params.append('longDescription',values.longDescription.value)

      dispatch(createservices(params))  
    }
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add Service</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2"> Name </label>
                <input name="title" placeholder="Name" type="text" className="form-control" />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Image</label>
                <input type="file" name="image" placeholder="Number" className="form-control" />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Logo</label>
                <input type="file" placeholder="Email" name="icon" className="form-control" />
              </div>

              <div className="col-md-6">
                <label className="pb-2">Short Description</label>
                <input type="text" name="shortDescription" placeholder="Enter Short Description" className="form-control" />
              </div>
              
              <div className="col-md-12 pt-3">
                <label className="pb-2">Long Description</label>
                <textarea type="text" rows="6" name="longDescription" placeholder="Enter Long Description" className="form-control" ></textarea>
              </div>
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add Service</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
