import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const AllOrderSlice=createSlice({
    name: "AllOrder",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setAllOrder(state,action){
            state.data=action.payload
        },
        getAllOrder(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setAllOrder,setStatus,getAllOrder}=AllOrderSlice.actions
export default AllOrderSlice.reducer




// thunk

export function fetchAllOrder(){
    return async function fetchAllOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/products`).then(
                (resp) => {

                    dispatch(setAllOrder(resp.data))
                })
                
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createAllOrder(param){
    
    return async function createAllOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/AllOrder/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("AllOrder Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateAllOrder(id){
    return async function updateAllOrderThunk(dispatch, getState){
        
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/AllOrder/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("AllOrder Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteAllOrder(id){
    return async function deleteAllOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/AllOrder/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdAllOrder(id){

    return async function getByIdAllOrderThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/AllOrder/${id}`).then(
                (resp) => {
                    dispatch(getAllOrder(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
