import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")
 
const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const shiftApproveSlice=createSlice({
    name: "shiftApprove",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        
        setshiftApprove(state,action){
            state.data=action.payload
        },
        getshiftApprove(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})






export const {setshiftApprove,setStatus,getshiftApprove}=shiftApproveSlice.actions
export default shiftApproveSlice.reducer




// thunk

export function fetchshiftApprove(){
 
    return async function fetchshiftApproveThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
           
            

            const params = new FormData();

            params.append("__api_key__", "hi,-its-eevee. I can do wonderful things in api creation.");

            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }
            await axios.post(`${api_url}/fetch_captain_shifts.php`,params,config).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setshiftApprove(resp?.data))
                })

                dispatch(setStatus(STATUS.IDLE))

        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createshiftApprove(param){
    
    return async function createshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/approve_shift.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("shift approve successfully")
                    const navigate = useNavigate();
                    navigate('/')  
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function createshiftReject(param){
    
    return async function createshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/reject_captain_shift.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("shift reject successfully")
                    const navigate = useNavigate();
                    navigate('/')  
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function createshiftAccept(param){
    
    return async function createshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/reject_captain_shift.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("shiftApprove Created")
                    const navigate = useNavigate();
                    navigate('/')  
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function DriverToggle(param){
    
    return async function createshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/toggle_captain_block.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("Toggle Update");
                   
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateshiftApprove(id){
    return async function updateshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/shiftApprove/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.state === "OK") {
                    message.success("shiftApprove Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteshiftApprove(id){
    return async function deleteshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/shiftApprove/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdshiftApprove(param){
    return async function createshiftApproveThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/fetch_captains.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
