import axios from 'axios'
import { api_url } from '../ApiUrl';
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const subSubCategorySlice=createSlice({
    name: "subSubCategory",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsubSubCategory(state,action){
            state.data=action.payload
        },
        getsubSubCategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsubSubCategory,setStatus,getsubSubCategory}=subSubCategorySlice.actions
export default subSubCategorySlice.reducer




// thunk

export function fetchsubSubCategory(){
    return async function fetchsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/subSubCategory`).then(
                (resp) => {
                    dispatch(setsubSubCategory(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createsubSubCategory(param){
    return async function createsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/subSubCategory/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("subSubCategory Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatesubSubCategory(id){
    return async function updatesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/subSubCategory/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("subSubCategory Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletesubSubCategory(id){
    return async function deletesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/subSubCategory/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsubSubCategory(id){
    return async function getByIdsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/subSubCategory/${id}`).then(
                (resp) => {
                    dispatch(getsubSubCategory(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
