import { message, Modal} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createDepartment } from "../../store/DepartmentSlice";
export const AddDepartment = () => {
 

  const dispatch = useDispatch();




  const HandleSubmit = (values)=>{
       console.log(values);
    if(values.name.value.length===0){

      message.error('Please enter your name')
    }
    

    else {
  

        const params = {
          'name':values.name.value
        }

      
      

      dispatch(createDepartment(params))  
    }
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add Department</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Department Name </label>
                <input name="name" placeholder="Name" type="text" className="form-control" />
              </div>
              
            </div>
           
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add Department</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
