import React, { useEffect, useState } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteusers, fetchusers } from "../store/usersSlice";
import { UsersToggle } from "../store/usersSlice";
import $ from "jquery";
import { Modal } from "antd";

function UsersTable() {
  const dispatch = useDispatch();

  const { data: users, status } = useSelector((state) => state?.users);
  console.log("ddddddddddddd", users);
  useEffect(() => {
    dispatch(fetchusers());

    $(document).ready(function () {
      setTimeout(function () {
        $("#AllData").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          bDestroy: true,
          // dom: 'Bfrtip',
          // buttons: ['copy', 'csv', 'print']
        });
      }, 1000);
    });

  }, []);

  

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        dispatch(deleteusers(id));
      },
    });
  };

  return (
    <>
      <div className="m-3">
        <div className=" card border-0 container bg-white p-4 shadow">
          <h3 className="text-start p-2">Users</h3>

          <div className=" row  text-end p-3  d-flex justify-content-between">
            {/* <div className='col-md-12'>
  <Link to="/users" className="btn btn-danger">Add</Link>
</div> */}
          </div>
          <table class="table" id="AllData">
            <thead>
              <tr>
                <th scope="col">#SL</th>
                <th scope="col">Username</th>
                <th scope="col">Date</th>
                {/* <th scope="col">Actve Customer</th> */}

                <th scope="col">Number</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">block Option</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td scope="row">{index + 1}</td>
                        <td>{item?.username}</td>
                        <td>{item?.createdAt}</td>
                        {/* <td>@mdo</td> */}
                        <td>{item?.phone}</td>
                        <td>{item?.email}</td>
                        <td>
                          {item?.status === "pending" ? (
                            <span className="badge text-bg-warning">
                              {item?.status}
                            </span>
                          ) : (
                            <span className="badge text-bg-success">
                              {item?.status}
                            </span>
                          )}
                        </td>

                        <td>
                          <label class="switch">
                            {item?.block == true ? (
                              <input
                                type="checkbox"
                                onClick={() => {
                                  dispatch(fetchusers());
                                  dispatch(UsersToggle(item?.id));
                                  dispatch(fetchusers());
                                }}
                                defaultChecked
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onClick={() => {
                                  dispatch(fetchusers());
                                  dispatch(UsersToggle(item?.id));
                                  dispatch(fetchusers());
                                }}
                              />
                            )}
                            <span class="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default UsersTable;
