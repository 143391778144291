import { message, Modal} from "antd";
import React, { useState ,useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";
import { createstaffs } from "../store/staffsSlice";
import { useParams } from "react-router-dom";
import { fetchusers } from "../store/usersSlice";
import { fetchDoctor } from "../store/DoctorSlice";
import { createLab } from "../store/LabSlice";
import FileUpload from "../Components/FileUpload";
export const LabeReportTable = () => {
  const dispatch = useDispatch();

  const {id} = useParams();

  const { data: users, status } = useSelector((state) => state?.users);
  console.log("ddddddddddddd", users);
  useEffect(() => {
    dispatch(fetchusers());
  }, []);


  const { data: Doctor, status2 } = useSelector((state) => state?.Doctor);
  console.log("ddddddddddddd", Doctor);
  useEffect(() => {
    dispatch(fetchDoctor());
  }, []);




  const [fileupload,setUpload] = useState([])
console.log('file upload',fileupload);



const [myattach,setmyattach] = useState([])

console.log('my attach',myattach);



const profileHandler= (e)=>{

   
 let temp = [...fileupload,e]

 setUpload(temp)

}




  const HandleSubmit = (values)=>{
       console.log(values);
    if(values.title.value.length===0){

      message.error('Please enter your title')
    }
    else if (myattach.length===0){
      message.error('Please enter your attachment')
    }
    else if (values.description.value.length===0){

      message.error('Please enter your description')

    }

    else if (values.doctorId.value.length===0){

      message.error('Please enter your doctorId')

    }

    
    else if (values.userId.value.length===0){

      message.error('Please enter your userId')

    }

    else {

      const params = new FormData()

      params.append('title', values.title.value)
      console.log(fileupload.length,'=====================>Checking upload')
      for (let i = 0; i < myattach.length; i++) {
        params.append('attachment', myattach[i])
      }
      // params.append('attachment',fileupload)
      params.append('description', values.description.value)
      params.append('doctorId', values.doctorId.value)
      params.append('userId', values.userId.value)

      dispatch(createLab(params))  
    }
  }


  







  return (
    <>
      <div ClassName="p-4 m-4">
      
        <form onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
          <div className="bg-white p-3 m-4 rounded-3 shadow">
          <h3 className="text-start p-2">Add Lab Reports</h3>
            <div className=" row  p-3 g-4 d-flex justify-content-between">

              <div className="col-md-6 ">
                <label>username</label>
              <select
              name="userId"
                    className="form-select "
                    aria-label="Default select example"
                  >
                    <option selected>Select User</option>

                    {users.length>0?users?.map((item,index)=>{
                      return (
                        <>
                           <option value={item?.id}>{item?.username}</option>
                        </>
                      )
                    }):''}
                 
               
                  </select>
              </div>
              <div className="col-md-6 ">
                <label>Doctor Name</label>
              <select
                    className="form-select "
                    name="doctorId"
                    aria-label="Default select example"
                  >
                    <option selected>Select Doctor</option>

                    {Doctor.length>0?Doctor?.map((item,index)=>{
                      return (
                        <>
                           <option value={item?.id}>{item?.name}</option>
                        </>
                      )
                    }):''}
                 
               
                  </select>
              </div>
              <div className="col-md-6">
                <label>Title</label>
                <input name="title" type="text" className="form-control" />
              </div>
              
              <div className="col-md-6 mt-4">
                <label>Description</label>
                <input type="text" name="description" className="form-control" />
              </div>


              <div className="col-md-6 mt-4">
                <label>Attachment</label>
                <input type="file" name="attachment" onChange={(e)=>{
                  setmyattach([...myattach,e.target.files[0]]);
                  // loadFile(e)
                }} className="form-control" />
              </div>


              <div className="col-md-6 mt-4">
                <label>Select Attachment</label>
                <br/>
                {myattach.length > 0? myattach.map((i,index)=>{
                  return(

                    <img src={URL.createObjectURL(i)}  className="me-3 border rounded-3" width="50px" />
                  )
                }):""}
              </div>

              {/* <div className="col-md-8 mx-auto  pb-4">
                      <label className="pb-2">Attachment:</label>
                    <FileUpload
                    name=""
                    fileupload={profileHandler} 
           />
                    </div> */}

                    {/*           fileupload={profileHandler}  */}
              


            </div>
          
           
            
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
