import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const sliderSlice=createSlice({
    name: "slider",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setslider(state,action){
            state.data=action.payload
        },
        getslider(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setslider,setStatus,getslider}=sliderSlice.actions
export default sliderSlice.reducer




// thunk

export function fetchslider(){
    return async function fetchsliderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}slider/get`).then(
                (resp) => {
                    dispatch(setslider(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createslider(param){
    return async function createsliderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}slider/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("slider Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Slider Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateslider(id,param){
    return async function updatesliderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}slider/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("slider Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Slider Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteslider(id){
    return async function deletesliderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/slider/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdslider(id){
    return async function getByIdsliderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}slider/get/${id}`).then(
                (resp) => {
                    dispatch(getslider(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
