import { message, Modal} from "antd";
import React, { useState ,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { updateservices } from "../../store/servicesSlice";
import { getByIdservices } from "../../store/servicesSlice";
export const EditServices = () => {
 
    const dispatch = useDispatch();

    const { data: services, status } = useSelector((state) => state?.services);
    console.log("ddddddddddddd", services);
  
  
  
    const {id} = useParams();
  
    useEffect(()=>{
       dispatch(getByIdservices(id))
    },[])




  const HandleSubmit = (values)=>{
       console.log(values);
    

        console.log(values.icon.files[0])
        console.log(values.image.files[0])

        const params = new FormData()
        params.append('title',values.title.value)
        params.append('icon',values.icon.files[0])
        params.append('image',values.image.files[0])
        params.append('shortDescription',values.shortDescription.value)
        params.append('longDescription',values.longDescription.value)

      dispatch(updateservices(id,params))  
    
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add Service</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2"> Name </label>
                <input name="title" placeholder="Name" type="text" className="form-control" 
                 defaultValue={services?.title}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Image</label>
                <input type="file" name="image" placeholder="Number" className="form-control"
                   defaultValue={services?.image}
                 />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Logo</label>
                <input type="file" placeholder="Email" name="icon" className="form-control"
                   defaultValue={services?.icon}
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2">Short Description</label>
                <input type="text" name="shortDescription" placeholder="Enter Short Description" className="form-control" 
                  defaultValue={services?.shortDescription}
                
                />
              </div>
              
              <div className="col-md-12 pt-3">
                <label className="pb-2">Long Description</label>
                <textarea type="text" rows="6" name="longDescription" placeholder="Enter Long Description" className="form-control"
                   defaultValue={services?.longDescription}
                ></textarea>
              </div>
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add Service</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
