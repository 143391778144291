import { Link, NavLink } from "react-router-dom";
import React, { useEffect,useState } from "react";
import { api_url } from "../ApiUrl";
import axios from "axios";

function Sidebar({ width, display }) {

    const NavLinkStyle = ({ isActive }) => {
        return {
            background: isActive ? 'rgba(255,255,255,0.4)' : '',
            borderLeft: isActive ? '0px' : 'none',
            borderRadius: isActive ? '30px' : 'none',
            margin: isActive ? '5px' : 'none',
            color: isActive ? 'white' : '',
        }
    }


    const [myStaff,setMyStaff]=useState('')

    useEffect(() => {

        axios.get(`${api_url}staff/get/${localStorage.getItem('staffRegister')}`,).then(res=>{
            console.log((res?.data))
            setMyStaff(res?.data?.data)
            if (res?.data?.status == "ok") {
                setMyStaff(res?.data?.data)
            } 
        })
    }, []);





    return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{ width: width }}>
            <div className="sidebar-header p-5 pb-2 pt-3" >
                <div className="bg-white p-2 rounded-4 text-center">
                    <img
                        src={require('../logo.png')}
                        className="logo-icon"
                        alt="logo icon"
                        width='160px'
                    />
                </div>
            </div>
            <ul className="metismenu">

            {myStaff?.permission && JSON.parse(myStaff?.permission)?.map((i, index) => {
                          return (
                            <>
                <li>
                    <NavLink style={NavLinkStyle}
                        to={`/${i}`}
                        className="accordion-button d-flex gap-2"
                        type="button"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">{i}</div>
                    </NavLink>
                </li>

                </>
                          );
                        })}

                {/* <li>
                    <NavLink style={NavLinkStyle}
                        to="/doctor"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Doctor
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/department"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Department
                        </div>
                    </NavLink>

                </li>

                <li>
                    <NavLink style={NavLinkStyle}
                        to="/labReportTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Lab Reports Add</div>
                    </NavLink>

                </li>


                <li>
                    <NavLink style={NavLinkStyle}
                        to='/labReportsHistoryTable'
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Lab Reports History</div>
                    </NavLink>

                </li>

                <li>
                    <NavLink style={NavLinkStyle}
                        to="/charge_management"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Charger Management
                        </div>
                    </NavLink>

                </li>


                <li>
                    <NavLink style={NavLinkStyle}
                        to="/UsersTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Users
                        </div>
                    </NavLink>

                </li>

                <li>
                    <NavLink style={NavLinkStyle}
                        to='/StaffTable'
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Staffs
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to='/RegisterCardTable'
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Registration Card Pending
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/RegisterCardlTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Registration Card List
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to='/donationHistoryTable'
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Donation History
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/AppointmentTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Appointments Today
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/AppointmentHistoryTable"
                        className="accordion-button  d-flex gap-2"
                        type="button">
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Appointment History
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/VisitorsTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Visitors Total
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/monthlyRevenueTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Monthly Revenue Report
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/payment_bank"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Payment Bank Alfala
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/news"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Latest News
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/services"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Services
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/add_websiteSetting"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Website Settings
                        </div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/Slider Settings"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Slider Settings
                        </div>
                    </NavLink>

                </li> */}
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/"
                        className="accordion-button  d-flex gap-2"
                        type="button"

                    >
                        <div className="parent-icon">
                            <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Logout
                        </div>
                    </NavLink>

                </li>


            </ul>
        </aside>

    );

}

export default Sidebar