import { message, Modal} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createnews } from "../../store/newsSlice";
import { fetchnews } from "../../store/newsSlice";
export const AddNews = () => {
 

  const dispatch = useDispatch();

  const [permssion, setPermissions] = useState([]);
  console.log(permssion);
  const perdata = (e) => {
    let temp = [...permssion, e.target.value];
    setPermissions(temp);
  };


  const HandleSubmit = (values)=>{
       console.log(values);
    if(values.title.value.length===0){

      message.error('Please enter your title')
    }
   else if(values.image.files.length===0){
      message.error('Please enter your email')

    }else if (values.description.value.length===0){

      message.error('Please enter your password')

    }

    else {

        const params = new FormData()

        params.append('title',values.title.value)
        params.append('image',values.image.files[0])
        params.append('description',values.description.value)

      dispatch(createnews(params))  
    }
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add News</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2"> Name </label>
                <input name="title" placeholder="Name" type="text" className="form-control" />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Image</label>
                <input type="file" name="image" placeholder="Number" className="form-control" />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              

              
              <div className="col-md-12">
                <label className="pb-2">Description</label>
                <textarea type="text" rows="6" name="description" placeholder="Enter Long Description" className="form-control" ></textarea>
              </div>
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add News</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
