import React from 'react'
import { Link } from 'react-router-dom'
function RegisterCardpending() {
  return (
    <>
      <div ClassName="m-3">

        <h3 className='text-start p-2'>Register Card Pending</h3>
        <div className='bg-white p-3 m-4 shadow'>

          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Cutomer Name </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Customer  Number  </label>
              <input type="text" className='form-control' />
            </div>
          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Payment Status </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Date of Registration </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Date of Issue </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Card Number  </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-12'>
              <buton className="btn btn-primary">Register</buton>
            </div>


          </div>
        </div>
      </div>
   


</>
  )
}

export default RegisterCardpending