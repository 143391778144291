import logo from './logo.svg';
import './App.css';

import Login from './Pages/Login';
import OutRoutes from './Pages/OutRoutes';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import LabReportsAdd from './Pages/LabReportsAdd';
import {LabeReportTable} from './Pages/LabeReportTable';
import LabReportHistory from './Pages/LabReportHistory';
import LabReportsHistoryTable from './Pages/LabReportsHistoryTable';
import Users from './Pages/Users.js';
import UsersTable from './Pages/UsersTable';
import { HiStatusOffline } from 'react-icons/hi';
import { Staffs } from './Pages/Staffs';
import StaffsTable from './Pages/StaffsTable';
import RegisterCardpending from './Pages/RegisterCardpending';

import DonationHistroy from './Pages/EditDonation';
import Appointment from './Pages/Appointment';
import AppointmentHistory from './Pages/AppointmentHistory';
import Visitors from './Pages/Visitors';
import MonthlyRe from './Pages/MonthlyRe';
import AppointmentTable from './Pages/AppointmentTable';
import RegisterCardL from './Pages/RegisterCardL';
import RegisterCardPendingTable from './Pages/RegisterCardPendingTable';
import VisitorsTable from './Pages/VisitorsTable';
import RegisterCardlistTable from './Pages/RegisterCardlistTable';
import DonationHistoryTable from './Pages/DonationHistoryTable';
import MonthlyRevTable from './Pages/MonthlyRevTable';
import AppointmentTodayTable from './Pages/AppointmentTodayTable';
import { EditStaffs } from './Pages/EditStaffs';
import { AddAppointmentToday } from './Pages/AddAppointmentToday';
import PaymentBank from './Pages/paymentBabk/PaymentBank';
import News from './Pages/news/News';
import Services from './Pages/services/Services';
import { AddServices } from './Pages/services/AddServices';
import { AddNews } from './Pages/news/AddNews';
import WebsiteSetting from './Pages/websiteSetting/WebsiteSetting';
import { AddWebsiteSetting } from './Pages/websiteSetting/AddWebsiteSetting';
import { EditServices } from './Pages/services/EditServices';
import { EditNews } from './Pages/news/EditNews';
import Doctor from './Pages/Doctor/Doctor';
import { AddDoctor } from './Pages/Doctor/AddDoctor';
import { AddDepartment } from './Pages/Department/AddDepartment';
import Department from './Pages/Department/Department';
import { EditDoctor } from './Pages/Doctor/EditDoctor';
import { EditDepartment } from './Pages/Department/EditDepartment';
import {ChargeManagement} from './Pages/ChargeManagement';
import { AddSliderSetting } from './Pages/websiteSetting/AddSliderSetting';
import { EditSliderSetting } from './Pages/websiteSetting/EditSliderSetting';
import RegistrationCard from './Pages/RegistrationCard';
import { Edit_labReports } from './Pages/Edit_labReports';
import {About} from './Pages/About/About';
import EditDonation from './Pages/EditDonation';
import EditCardRegistraion from './Pages/EditCardRegistraion';
import Edit_AppointmentHistory from './Pages/Edit_AppointmentHistory';
import SliderSetting from './Pages/websiteSetting/SliderSetting';



function App() {
  return (
    <Routes>
        <Route path='/' element={<Login/>} />
        <Route element={<OutRoutes/>}>
        <Route path='/Dashboard' element={<Dashboard/>} />
        <Route path='/labReportsAdd' element={<LabReportsAdd/>} />
        <Route path='/Lab Reports Add' element={<LabeReportTable/>} />
        <Route path='/labReportsHistory' element={<LabReportHistory/>} />
        <Route path='/Lab Reports History' element={<LabReportsHistoryTable/>} />

        <Route path='/edit_labReports/:id' element={<Edit_labReports/>} />
        {/* <Route path='/Users' element={<Users/>} /> */}
        <Route path='/Users' element={<UsersTable/>}/>
        <Route path='/Staffs' element={<StaffsTable/>} />
        <Route path='/StaffTable' element={<Staffs/>} />
        <Route path='/edit_staff/:id' element={<EditStaffs/>} />
        <Route path='/RegisterCardpending' element={<RegisterCardpending/>} />
        <Route path='/Registration Card Pending' element={<RegisterCardPendingTable/>} />
        <Route path='/RegisterCardl' element={<RegisterCardL/>} />
        <Route path='/Registration Card List' element={<RegisterCardlistTable/>} />
        <Route  path='/edit_card_registraion/:id' element={<EditCardRegistraion/>}  />
         <Route  path='/edit_donation/:id'   element={<EditDonation/>} />
        <Route path='/Donation History' element={<DonationHistoryTable/>} />
        <Route path='/Appointment' element={<Appointment/>} />
        <Route path='/Appointments Today' element={<AppointmentTodayTable/>} />
        
        <Route path='/add_appointment' element={<AddAppointmentToday/>} />
        <Route path='/AppointmentHistory' element={<AppointmentHistory/>} />
        <Route path='/edit_appointment/:id' element={<Edit_AppointmentHistory/>}  />
        <Route path='/Appointment History' element={<AppointmentTable/>} />
        <Route path='/Visitors' element={<Visitors/>} />
        <Route path='/Visitors Total' element={<VisitorsTable/>} />
        <Route path='/monthlyRevenueReport' element={<MonthlyRe/>} />
        <Route path='/Monthly Revenue Report' element={<MonthlyRevTable/>} />
        <Route path='/Payment Bank Alfala' element={<PaymentBank/>}  />
        <Route path='/Latest News' element={<News/>} />
        <Route path='/add_news' element={<AddNews/>} />
        <Route path='/edit_news/:id' element={<EditNews/>}  />
        <Route path='/Services' element={<Services/>} />
        <Route path='/add_service' element={<AddServices/>} />
        <Route path='/websiteSetting' element={<WebsiteSetting/>} />
        <Route path='/Website Settings' element={<AddWebsiteSetting/>} />
        <Route path='/edit_service/:id' element={<EditServices/>} />
        <Route path='/Slider Settings' element={<SliderSetting/>} />
        <Route path='/add_sliderSetting' element={<AddSliderSetting/>} /> 
        <Route path='/edit_slider/:id' element={<EditSliderSetting/>} />

        <Route path='/Doctor' element={<Doctor/>} />
        <Route path='/add_doctor' element={<AddDoctor/>} />
        <Route path='/edit_doctor/:id' element={<EditDoctor/>} />

        <Route path='/Department' element={<Department/>} />
        <Route path='/add_department' element={<AddDepartment/>} />
        <Route path='/edit_department/:id' element={<EditDepartment/>} />
        <Route path='/Charge Management' element={<ChargeManagement/>}  />
        <Route  path='/card/:id' element={<RegistrationCard/>} />
        <Route path='/edit_doctor/:id' element={<Doctor/>} />

        <Route  path='/About Settings' element={<About/>} />
        </Route>
    </Routes>
  );
}

export default App;
