import React from 'react'
import { Link } from 'react-router-dom'
function LabReportsAdd() {
  return (
    <>
    
    <div className='m-5'>

    <h3 className='text-start p-2'>Labe Reports Add</h3>
    <div className='bg-white p-3 shadow '>
    


    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Add Button </label>
<input type="text" className='form-control' />
</div>
<div className='col-md-6'>
  <label> Name and Customer </label>
<input type="text" className='form-control' />
</div>


    </div>
    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Customer Name Detail </label>
<input type="text" className='form-control' />
</div>
<div className='col-md-6'>
  <label> Attachment Detail </label>
<input type="text" className='form-control' />
</div>

    </div>

    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Summit option</label>
<input type="text" className='form-control' />
</div>
<div className='col-md-6'>
  <label> same ass labe report </label>
<input type="text" className='form-control' />
</div>

    </div>
    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Edit option</label>
<input type="text" className='form-control' />
</div>


    </div>

    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-12'>
  <buton className="btn btn-primary">Register</buton>
</div>


    </div>
    






    </div>
    </div>



    </>
  )
}

export default LabReportsAdd