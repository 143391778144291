import React from 'react'
import { Link } from 'react-router-dom';
function AppointmentHistory() {
  return (
    <>
 <div className=' m-5'>


 <h3 className='text-start p-2'>Appointment History</h3>
 <div className=' p-3 shadow shadow bg-white'>
 <div className='row p-3 d-flex justify-content-between'>
<div className='col-md-6'>
  <label> Name </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Number </label>
<input type="text" className='form-control ' />
</div>
</div>
 <div className='row p-3 d-flex justify-content-between'>
<div className='col-md-6'>
  <label>Customer Name </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Customer Number </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className='row p-3 d-flex justify-content-between'>
<div className='col-md-6'>
  <label> Payment Status </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Date of Registration </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className='row p-3 d-flex justify-content-between'>
<div className='col-md-6'>
  <label> Date of Issue </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Card Number </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3 d-flex justify-content-between'>

<div className='col-md-12'>
<button type="Register" className='btn btn-primary' >Register</button>
</div>


</div>

 </div>


</div>

    </>
  )
}





export default AppointmentHistory