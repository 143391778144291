import React from 'react'
import {HiMenuAlt3} from 'react-icons/hi';
import {GoSearch} from 'react-icons/go';
import {MdMessage} from 'react-icons/md';
import {MdNotifications} from 'react-icons/md'
const Navbar = ({ Toggle }) => {
    return (
        <div className='d-flex bg-white border justify-content-between align-items-center p-2'>
            <div className='d-flex align-items-center '>
                <HiMenuAlt3 size={30} color="#222FB9" onClick={Toggle} style={{cursor:'pointer'}}/>
                <div>
                    <h4 className='m-0'>Dashboard</h4>
                </div>
            </div>
            <div>
                <div className="input-group mb-3">
                    
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Username"
                        placeholder='Search here....'
                        aria-describedby="basic-addon1"
                    />
                    <span className="input-group-text bg-white" id="basic-addon1">
                        <GoSearch size={20} color="#000" />
                    </span>
                </div>

            </div>
            <div className='d-flex'>
                <div>
                   <MdMessage size={20} color="#222FB9"/>
                </div>
                <div>
                   <MdNotifications size={20} color="#222FB9" />
                </div>
                <div></div>
            </div>

        </div>
    )
}

export default Navbar
