import React, { useEffect, useState } from "react";
import {
  getByIdappointment,
  updateappointment,
} from "../store/appointmentSlice";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchappointment } from "../store/appointmentSlice";
import { fetchDoctor } from "../store/DoctorSlice";
import { fetchDepartment } from "../store/DepartmentSlice";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../ApiUrl";

const Edit_AppointmentHistory = () => {
  const dispatch = useDispatch();
  const { data: Department, status } = useSelector(
    (state) => state?.Department
  );
  useEffect(() => {
    dispatch(fetchDepartment());
  }, []);

  const { data: Doctor, status2 } = useSelector((state) => state?.Doctor);
  useEffect(() => {
    dispatch(fetchDoctor());
  }, []);

  const [appointment,setAppointment]=useState(null)

  console.log("ddddddddddddd", appointment);

  const { id } = useParams();


  useEffect(()=>{
    axios.get(`${api_url}appointment/get/${id}`).then(res=>{
      setAppointment(res.data.data)
    })

  },[])

  const HandleSubmit = (values) => {
    console.log(values);

    const params = {
      departmentId: values.departmentId.value,
      doctorId: values.doctorId.value,
      phone: values.phone.value,
      description: values.description.value,
      appointmentDate: values.appointmentDate.value,
      appointmentTime: values.appointmentTime.value,
    };

    dispatch(updateappointment(id,params));
  };

  return (
    <>
      <div className="m-4">
        <h3 className="text-start p-2">Edit Appointment</h3>

        <div className=" card rounded border-0 p-4 shadow bg-white">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              HandleSubmit(e.target);
            }}
            className="bg-white m-2  books_ap rounded-5  p-4 d-flex flex-column gap-3 position-relative"
            style={{ right: "20px", top: "5px" }}
          >
            <div className="row g-4">
              <div className="col-md-6">
                <label className="pb-2">Department Name</label>
                <select
                  className="form-select py-2 "
                  name="departmentId"
                  aria-label="Default select example"
                  defaultValue={appointment?.departmentId}
                >
                  {Department?.map((item, index) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-6">
                <label className="pb-2">Doctor Name</label>
                <select
                  className="form-select py-2"
                  name="doctorId"
                  aria-label="Default select example"
                  defaultValue={appointment?.doctorId}
                >
                  {Doctor?.map((item, index) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6">
                <label className="pb-2">Phone</label>
                <input
                  type="number"
                  class="form-control py-2  ps-3"
                  id="exampleInputEmail1"
                  name="phone"
                  placeholder="Phone number"
                  defaultValue={appointment?.phone}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Appointment Date</label>
                <input
                  type="date"
                  class="form-control   ps-3"
                  id="exampleInputEmail1"
                  name="appointmentDate"
                  defaultValue={appointment?.appointmentDate}
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Appointment Time</label>
                <input
                  type="time"
                  class="form-control py-2  ps-3"
                  name="appointmentTime"
                  id="exampleInputEmail1"
                  defaultValue={appointment?.appointmentTime}
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2">Description</label>
                <input
                  type="text"
                  class="form-control py-2  ps-3"
                  id="description"
                  name="description"
                  defaultValue={appointment?.description}
                  placeholder="Your description"
                ></input>
              </div>
            </div>

            <div className=" m-2">
              <button type="submit" className="btn btn-primary rounded-3 px-5 ">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Edit_AppointmentHistory;
