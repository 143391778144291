import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { fetchstaffs } from '../store/staffsSlice';
import { deletestaffs } from '../store/staffsSlice';
import { message, Modal } from 'antd';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { deleteLab, fetchLab } from '../store/LabSlice';
function LabReportsHistoryTable() {

  

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {

        dispatch(deleteLab(id))

        



      },
    });
  };
  const dispatch = useDispatch();

  const { data: Lab, status } = useSelector((state) => state?.Lab);
  console.log('ddddddddddddd', Lab);
  useEffect(() => {
    dispatch(fetchLab());

    $(document).ready(function () {
      setTimeout(function () {
        $('#AllData').DataTable(
          {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            bDestroy: true,
            dom: 'Bfrtip',
            buttons: ['copy', 'csv', 'print']
          }
        );
      },
        1000
      );
    });

  }, [])
  return (
    <>
      <div className='p-4'>
       
        <div className=' card container border-0 bg-white p-4 shadow'>
    
        <div className="row pb-4 align-items-center" >
                <div className="col-md-6">
                <h3>Lab Reports</h3>
                </div>
               
              </div>
          <div className=' row  p-3  d-flex justify-content-between'>

    


          </div>
          <table class="table" id='AllData'>
            <thead>
              <tr>
                <th scope="col">SL</th>
                <th scope="col">Title</th>
                <th scope="col">User</th>
                <th scope="col">Doctor</th>
                {/* <th scope="col">Attachment</th> */}
                <th scope="col">Description</th>
                <th scope="col">Edit Option</th>
              </tr>
            </thead>
            <tbody>
              {Lab.length > 0 ? Lab?.map((item, index) => {
                return (
                  <>
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.title}</td>
                      <td>{item?.userl?.username}</td>
                      <td>{item?.doctor?.name}</td>
{/*                           
      <td>

{item>0?JSON?.parse(item?.attachment)?.map(i=>
<a href={i} download target="_blank">
<img src={i} className='rounded-circle' width="40px" height="40px" alt='' />
</a>
  ):''}
</td> */}
                      <td>{item?.description}</td>
                      <td>
                        <div className=" d-flex justify-content-center">

                          <Link to={`/edit_labReports/${item?.id}`} ><i style={{ color: 'blue', fontSize: '20px' }} class="bi bi-pencil-square"></i></Link>
                          <i

                            onClick={() => {
                              onDeleteStudent(item?.id);
                            }}
                            class="bi bi-trash3 ms-3" style={{ color: 'red', fontSize: '20px' }} ></i>

                        </div>

                      </td>
                    </tr>
                  </>
                )
              }) : ''}


            </tbody>
          </table>







        </div>








      </div>





    </>
  )
}

export default LabReportsHistoryTable