import { message, Modal} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector} from "react-redux";
import {updatestaffs} from "../store/staffsSlice";
import { useParams } from "react-router-dom";
import { getByIdstaffs } from "../store/staffsSlice";
export const EditStaffs = () => {
  const dispatch = useDispatch();

  const { data: staffs, status } = useSelector((state) => state?.staffs);
  console.log("ddddddddddddd", staffs);



  const {id} = useParams();

  useEffect(()=>{
     dispatch(getByIdstaffs(id))
  },[])
   
  const [permssion, setPermissions] = useState([]);

  console.log(permssion);
  const perdata = (e) => {
    let temp = [...permssion, e.target.value];
    setPermissions(temp);
  };


  const HandleSubmit = (values)=>{
       console.log(values);

       const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      const checkboxValues = [];

      checkboxes.forEach(checkbox => {
        if (checkbox.checked) {
          checkboxValues.push(checkbox.value);
        }
      });

      dispatch(updatestaffs(id,{
         
        'username':values.username.value,
        'phone':values.phone.value,
        'email':values.email.value,
        'permission':checkboxValues,
         'password':values.password.value
        

      }))  
    
  }




  return (
    <>
      <div ClassName="p-4">
        <h3 className="text-start p-2">Staffs</h3>
        <form onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
          <div className="bg-white p-3 rounded-3 shadow">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label> Name </label>
                <input name="username" type="text" className="form-control"
                  defaultValue={staffs?.username}
                />
              </div>
              <div className="col-md-6">
                <label>Number</label>
                <input type="text" name="phone" className="form-control" defaultValue={staffs?.phone} />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label> Email </label>
                <input type="email" name="email" className="form-control"
                 defaultValue={staffs?.email}
                />
              </div>

              <div className="col-md-6">
                <label> Password </label>
                <input type="text" name="password" className="form-control" 
                
                  defaultValue={staffs?.password}
                />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Selected Permissions:</label>
                  <div className="d-flex gap-3 flex-wrap">
                  {staffs?.permission && JSON.parse(staffs?.permission).map(i=>{
                          return(<>
                  <div class="mb-3 ms-2 form-check">
                      
                            <input
                            style={{ width: "18px", height: "18px" }}
                            type="checkbox"
                            class="form-check-input"
                            id={i}
                            value={i}
                            onClick={(e) => perdata(e)} 
                            defaultChecked
                          />   
                            
                      
                      <label class="form-check-label" htmlFor={i}>
                      {i}
                      </label>
                    </div>
                    </>)                      
                  })}

                  </div>
                </div>
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Add New Permissions:</label>
                  <div className="d-flex flex-wrap">
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Dashboard"
                        value="Dashboard"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" htmlFor="Dashboard">
                        Dashboard
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Doctor"
                        value="Doctor"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Doctor">
                        Doctor
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Department"
                        value="Department"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Department">
                        Department
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Lab Reports Add"
                        value="Lab Reports Add"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Lab Reports Add">
                      Lab Reports Add
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Lab Reports History"
                        value="Lab Reports History"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Lab Reports History">
                      Lab Reports History
                      </label>
                    </div>
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Charge Management"
                        value="Charge Management"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Charge Management">
                      Charge Management
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Users"
                        value="Users"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Users">
                      Users
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Staffs"
                        value="Staffs"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Staffs">
                      Staffs
                      </label>
                    </div>



                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Registration Card Pending"
                        value="Registration Card Pending"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Registration Card Pending">
                      Registration Card Pending
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Registration Card List"
                        value="Registration Card List"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Registration Card List">
                      Registration Card List
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Donation History"
                        value="Donation History"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Donation History">
                      Donation History
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Appointments Today"
                        value="Appointments Today"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Appointments Today">
                      Appointments Today
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Appointment History"
                        value="Appointment History"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Appointment History">
                      Appointment History
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Visitors Total"
                        value="Visitors Total"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Visitors Total">
                      Visitors Total
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Monthly Revenue Report"
                        value="Monthly Revenue Report"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Monthly Revenue Report">
                      Monthly Revenue Report
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Payment Bank Alfala"
                        value="Payment Bank Alfala"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Payment Bank Alfala">
                      Payment Bank Alfala
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Latest News"
                        value="Latest News"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Latest News">
                      Latest News
                      </label>
                    </div>


                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Services"
                        value="Services"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Services">
                      Services
                      </label>
                    </div>


                    
                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="About Settings"
                        value="About Settings"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="About Settings">
                      About Settings
                      </label>
                    </div>

                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Website Settings"
                        value="Website Settings"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Website Settings">
                      Website Settings
                      </label>
                    </div>



                    <div class="mb-3 ms-2 form-check">
                      <input
                        style={{ width: "18px", height: "18px" }}
                        type="checkbox"
                        class="form-check-input"
                        id="Slider Settings"
                        value="Slider Settings"
                        onClick={(e) => perdata(e)}
                      />
                      <label class="form-check-label" for="Slider Settings">
                      Slider Settings
                      </label>
                    </div>

                 

                 
                  </div>
                </div>
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Register</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
