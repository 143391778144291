import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const servicesSlice=createSlice({
    name: "services",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setservices(state,action){
            state.data=action.payload
        },
        getservices(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setservices,setStatus,getservices}=servicesSlice.actions
export default servicesSlice.reducer




// thunk

export function fetchservices(){
    return async function fetchservicesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}service/get`).then(
                (resp) => {
                    dispatch(setservices(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createservices(param){
    return async function createservicesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}service/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("services Created Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/services`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateservices(id,parm){
    return async function updateservicesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}service/update/${id}`,parm).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("services Updated Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/services`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteservices(id){
    return async function deleteservicesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}service/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                 
                  message.success("services Deleted Successfully!")
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdservices(id){
    return async function getByIdservicesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}service/get/${id}`).then(
                (resp) => {
                    dispatch(getservices(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
