import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDoctor } from "../../store/DoctorSlice";
import { deleteDoctor } from "../../store/DoctorSlice";
import { message, Modal } from "antd";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
function Doctor() {
  $(document).ready(function () {
    setTimeout(function () {
      $("#AllData").DataTable({
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        bDestroy: true,
        // dom: 'Bfrtip',
        // buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        dispatch(deleteDoctor(id));
      },
    });
  };

  const dispatch = useDispatch();

  const { data: Doctor, status } = useSelector((state) => state?.Doctor);
  console.log("ddddddddddddd", Doctor);
  useEffect(() => {
    dispatch(fetchDoctor());
  }, []);

  return (
    <>
      <section className="m-4 ">
        <div className="container-fluid border-0 rounded-4 bg-white p-4 shadow">
          <h3 className="text-start p-2">Doctor</h3>
          <div className=" row  p-3">
            <div className="col-md-12 text-end">
              <Link to="/add_doctor" className="btn mb-3 btn-danger">
                Add
              </Link>

              <div className="table-responsive" style={{ overflowX: "hidden" }}>
                <table class="table table-striped" id="AllData">
                  <thead>
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col"> Name</th>
                      <th scope="col">Image</th>
                      <th scope="col">Week</th>
                      <th scope="col">Start Time</th>
                      <th scope="col">End Time</th>
                      <th scope="col">Description</th>
                      <th scope="col">Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doctor.length > 0
                      ? Doctor &&
                        Doctor?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td scope="row">{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>
                                  <img
                                    src={item?.image}
                                    alt=""
                                    className="rounded-circle"
                                    width="30px"
                                    height="30px"
                                  />
                                </td>
                                <td>{item?.week}</td>
                                <td>{item?.startTime}</td>
                                <td>{item?.endTime}</td>
                                <td>{item?.designation}</td>
                                <td>
                                  <div className=" d-flex justify-content-center">
                                    <Link to={`/edit_doctor/${item?.id}`}>
                                      <i
                                        style={{
                                          color: "blue",
                                          fontSize: "20px",
                                        }}
                                        class="bi bi-pencil-square"
                                      ></i>
                                    </Link>
                                    <i
                                      onClick={() => {
                                        onDeleteStudent(item?.id);
                                      }}
                                      class="bi bi-trash3 ms-3"
                                      style={{ color: "red", fontSize: "20px" }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Doctor;
