import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const websitesSlice=createSlice({
    name: "websites",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setwebsites(state,action){
            state.data=action.payload
        },
        getwebsites(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setwebsites,setStatus,getwebsites}=websitesSlice.actions
export default websitesSlice.reducer




// thunk

export function fetchwebsites(){
    return async function fetchwebsitesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}website/get`).then(
                (resp) => {
                    dispatch(setwebsites(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createwebsites(param){
    return async function createwebsitesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}websites/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("websites Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Website Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatewebsites(id,param){
    return async function updatewebsitesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}website/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("websites Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Website Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletewebsites(id){
    return async function deletewebsitesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}websites/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdwebsites(id){
    return async function getByIdwebsitesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}website/get/${id}`).then(
                (resp) => {
                    dispatch(getwebsites(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
