import {configureStore} from '@reduxjs/toolkit';
import categoryReducer from './categorySlice'
import featuredReducer from './featuredSlice'
import dealDayReducer from './dealDaySlice'
import subSubCategoryReducer from './subSubCategorySlice'
import LabReducer from './LabSlice';
import AllSellersReducer from './SellerSlice';
import usersReducer from './usersSlice';
import rideCategoryReducer from './rideCategorySlice';
import rideSubCategoryReducer from './rideSubCategorySlice';
import tripsReducer from './DepartmentSlice';
import shiftsApproveReducer from './shiftsApproveSlice';
import staffsReducer from './staffsSlice';
import AllOrderReducer from './AllOrderSlice';
import newsReducer from './newsSlice';
import servicesReducer from './servicesSlice';
import websitesReducer from './websitesSlice';
import DepartmentReducer from './DepartmentSlice';
import DoctorReducer from './DoctorSlice'
import appointmentReducer from './appointmentSlice';
import managementReducer from './managementSlice';
import donationReducer from './donationSlice';
import cardReducer from './cardSlice';
import aboutReducer from './aboutSlice';
import sliderReducer from './sliderSlice';
const Store=configureStore({

    reducer:{
        slider:sliderReducer,
        about:aboutReducer,
        card:cardReducer,
        staffs:staffsReducer,
        AllSellers:AllSellersReducer,
        news:newsReducer,
        appointment:appointmentReducer,
        Doctor:DoctorReducer,
        services:servicesReducer,
        websites:websitesReducer, 
        category:categoryReducer,
        subSubCategory:subSubCategoryReducer,
        Department:DepartmentReducer,
        featured:featuredReducer,
        management:managementReducer,
         donation:donationReducer,
        dealDay:dealDayReducer,
        Lab:LabReducer,
        users:usersReducer,
        trips:tripsReducer,
        rideCategory:rideCategoryReducer,
        rideSubCategory:rideSubCategoryReducer,
        shiftApprove:shiftsApproveReducer,
        allOrder:AllOrderReducer
        
    }
})



export default Store;