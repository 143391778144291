import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { useDispatch ,useSelector} from "react-redux";
import { getByIddonation, updatedonation } from "../store/donationSlice";
import { useParams } from "react-router-dom";
const EditDonation = () => {
  const dispatch = useDispatch();
const [amount,setAmount] = useState(" ");


const userId = localStorage.getItem("userRegister");
console.log('+=======',userId);


const AddAmount = (e)=>{
    setAmount(e.target.value)
}



const { data: donation, status2 } = useSelector((state) => state?.donation);
console.log("ddddddddddddd", donation);



const {id} = useParams();

useEffect(()=>{
   dispatch(getByIddonation(id))
},[])





console.log(amount);
  const handle = (values) => {

 
    dispatch(
      updatedonation(id,{
        
        amount:values.amount.value,
        email:values.email.value,
        phone:values.phone.value,
        firstName:values.firstName.value,
        lastName:values.lastName.value,
        address:values.address.value,
        country:values.country.value,
        province:values.province.value,
        state:values.state.value,
        zipCode:values.zipCode.value,
        reason:values.reason.value,
        contribute:values.contribute.value,
      })
    );
  };

  return (
    <>
      <div className="container my-4 text-dark">
        <form className=" w-100 h-100" onSubmit={(e) => { e.preventDefault(); handle(e.target) }} >
          <div className="container mt-4">
            <h2 className="mb-0">Make a Donation</h2>
            <p className="fw-semibold">Lets donate now to safe people lives</p>

            <div className=" p-2 justify-content-center">
              {/* <div className="row text-center">
                <div className="col ">
                  <button type="button"
                  
                  className="btn border fw-bold fs-5">
                    $100
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn border fw-bold fs-5">
                    $300
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn border fw-bold fs-5">
                    $500
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn border fw-bold fs-5">
                    $1000
                  </button>
                </div>
                <div className="col">
                  <button type="button" className="btn border fw-bold fs-5">
                    Others
                  </button>
                </div>
              </div> */}
            </div>

            {/* <div className="">
              <div className="form-check fw-bold">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  style={{ fontSize: "12px" }}
                >
                 
                </label>
              </div>

              <div className="form-check fw-bold" onClick={() => {}}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  style={{ fontSize: "12px" }}
                >
                
                </label>
              </div>

              <div className="form-check fw-bold">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  style={{ fontSize: "12px" }}
                >
                  Make this a Monthly Gift
                </label>
              </div>
              <h6
                style={{ marginTop: "5%" }}
                className="text-danger text-start"
              >
               
              </h6>
            </div> */}
          </div>
          <div className="card shadow mb-5">
            <div className="px-5">
              <p className="fw-bold mt-3 border-bottom p-2">
                Donor Information
              </p>
              <div className="row g-4">
                <div className="col-md-6 text-start">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon3"
                    >
                      <i className="bi bi-person-fill text-dark fs-4"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control px-4"
                      name="firstName"
                      placeholder="First Name"

                      defaultValue={donation?.firstName}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-start">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon3"
                    >
                      <i className="bi bi-person-fill text-dark fs-4"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control px-4"
                      name="lastName"
                      placeholder="Last Name"
                      defaultValue={donation?.lastName}
                    />
                  </div>
                </div>

                <div className="col-md-6 text-start">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon3"
                    >
                      <i className="bi bi-person-fill text-dark fs-4"></i>
                    </span>
                    <input
                      type="email"
                      className="form-control px-4"
                
                      name="email"
                      id="email"
                      aria-describedby="basic-addon3"
                      placeholder="Email"
                      defaultValue={donation?.email}
                    />
                  </div>
                </div>

                <div className="col-md-6 text-start">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon3"
                    >
                      <i className="bi bi-person-fill text-dark fs-4"></i>
                    </span>
                    <input
                      type="address"
                      className="form-control px-4"
                      id="address"
                      name="address"
                      aria-describedby="basic-addon3"
                      placeholder="Address Line"
                      defaultValue={donation?.address}
                    />
                  </div>
                </div>

                <div className="col-md-12 mt-3 py-2">
                  <div className="row">
                    <div className="col-md-3">
                      <select
                        className="form-select form-select-lg mb-3 "
                        id="country"
                        name="country"
                      >
                      
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Aland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BQ">
                          Bonaire, Sint Eustatius and Saba
                        </option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">
                          Congo, Democratic Republic of the Congo
                        </option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Cote D'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curacao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="VA">
                          Holy See (Vatican City State)
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">
                          Lao People's Democratic Republic
                        </option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">
                          Macedonia, the Former Yugoslav Republic of
                        </option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">
                          Micronesia, Federated States of
                        </option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthelemy</option>
                        <option value="SH">Saint Helena</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="CS">Serbia and Montenegro</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.s.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control py-2 px-4"
                        id="province"
                        name="province"
                        aria-describedby="basic-addon3"
                        placeholder="--Province--"
                        defaultValue={donation?.province}
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control py-2 px-4"
                        id="state"
                        name="state"
                        aria-describedby="basic-addon3"
                        placeholder="--State--"
                        defaultValue={donation?.state}
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control py-2 px-4"
                        id="zipCode"
                        name="zipCode"
                        aria-describedby="basic-addon3"
                        placeholder="Zip Code"
                        defaultValue={donation?.zipCode}
                      />
                    </div>

                    <div className="input-group mb-3 col-md-6">
                      <input
                        type="phone"
                        className="form-control px-4 p-2 "
                        id="phone"
                        name="phone"
                        aria-describedby="basic-addon3"
                        placeholder="Phone number"
                        defaultValue={donation?.phone}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 text-start">
                  <p className="fw-bold mt-3 border-bottom border-top p-2">
                    Contribution
                  </p>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <select
                      className="form-select form-select-lg mb-3"
                      id="reason"
                      name="reason"
                    >
                     
                      <option value="Make this gift on behalf of an organization">  Make this gift on behalf of an organization</option>
                      <option value="I would like this gift to remain anonymous">I would like this gift to remain anonymous</option>
                      <option value="Make this  a monthly gift.">Make this  a monthly gift.</option>
                     
                    </select>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      style={{ padding: "11px" }}
                      className="text-center form-control"
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      defaultValue={donation?.amount}
                    />
                  </div>
                </div>

                <div className="col-md-12 text-start">
                  <p className="fw-bold mt-3 border-bottom border-top p-2">
                    Other
                  </p>
                </div>

                <div>
                  <p className="fw-semibold">
                    Please designate my contribution to
                  </p>
                  <div className="col-md-6 mt-3">
                    <select
                      className="form-select form-select-lg mb-3"
                      id="contribute"

                      name="contribute"
                    >
                      <option selected>--Select--</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                <div className="pb-3">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/#/bank"
                  >
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-md btn-info text-white fw-bold"
                    >
                      {" "}
                      Proceed
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditDonation;
