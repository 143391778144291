import React from 'react'
import { Link } from 'react-router-dom'
function Visitors() {
  return (
    <>
<div ClassName="m-3">

<h3 className='text-start p-2'>Visitors Total</h3>


<div className='bg-white p-3 m-4 shadow'>


          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Name Of vistors  </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label>Date of Registration </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Date of Issue</label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Active customer  </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label>Block Option </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Number/Whatsapp Number </label>
              <input type="text" className='form-control' />
            </div>


          </div>
           <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label>Email  </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Status </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-12'>
              <buton className="btn btn-primary">Register</buton>
            </div>


          </div>


</div>

</div>



        
    </>
  )
}

export default Visitors