import { message, Modal} from "antd";
import React, { useState ,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getByIdabout, updateabout } from "../../store/aboutSlice";
export const About = () => {
 
    const dispatch = useDispatch();

    const { data: about, status } = useSelector((state) => state?.about);
    console.log("ddddddddddddd", about);
  
  
    useEffect(()=>{
       dispatch(getByIdabout(1))
    },[])




  const HandleSubmit = (values)=>{
       console.log(values);
    
        const params = {
          'title':values.title.value,
          'description':values.description.value,
          'linkName':values.linkName.value,
          'linkUrl':values.linkUrl.value,

        }

      dispatch(updateabout(1,params))  
    
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        
          <div className="bg-white p-3 rounded-3 shadow m-4">
          <h3 className="text-start p-2">About Setting</h3>
            <div className=" row g-4 p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Title</label>
                <input name="title" placeholder="Name" type="text" className="form-control" 
                 defaultValue={about?.title}
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2">Link Name</label>
                <input name="linkName" placeholder="" type="text" className="form-control" 
                 defaultValue={about?.linkName}  
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2">Link Url</label>
                <input name="linkUrl" placeholder="" type="text" className="form-control" 
                 defaultValue={about?.linkUrl}
                />
              </div>

              <div className="col-md-12">
                <label className="pb-2">Description</label>
                <textarea rows="6" name="description" placeholder="" type="text" className="form-control" 
                 defaultValue={about?.description}
                ></textarea>
              </div>
            
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
